import React from 'react';
import { graphql } from 'gatsby';

import Container from '../../components/Container';
import Hero from '../../components/Hero';
import Layout from '../../components/Layout/Layout';
import Seo from '../../components/seo';

import * as styles from './index.module.css';

const StorePage = ({ data: { pageData } }) => {
  return (
    <Layout disablePaddingBottom>
      <Seo
        title={pageData?.title || ""}
        description={(pageData.content || "")?.substring(0, 50)}
        banner={pageData?.featured_media || ""}
        pathname={`/${pageData?.slug || ""}/`}
      />
      <div className={styles.root}>
        {/* Hero Container */}
        <Hero
          maxWidth={'900px'}
          image={pageData?.featured_media}
          title={pageData?.title}
        />

        <Container size={'large'} spacing={'min'}>
          <div
            className={styles.detailContainer}
            dangerouslySetInnerHTML={{ __html: pageData.content || "" }}
          />
        </Container>

      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    pageData: zzPage(
      id: {eq: $id}
    ) {
      id
      slug
      title
      type
      date
      modified
      content
      featured_media
    }
  }
`;

export default StorePage;
