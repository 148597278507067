import React from 'react';
import * as styles from './Hero.module.css';
import Button from '../Button';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getImageLink } from '../../helpers/images';

const Hero = ({
  title,
  subtitle,
  ctaText,
  ctaAction,
  image,
  maxWidth,
  ctaStyle,
  ctaLink,
  ctaTo,
  header,
}) => {
  const fallback = getImageLink(image, { formats: 'webp', query: '&q=1&blur=100' });
  const heroImage = {
    backgroundColor: '#B59F66aa',
    placeholder: {
      fallback
    },
    layout: 'constrained',
    images: {
      fallback: {
        src: image || '/banner1.png',
      },
      sources: [
        {
          media: '',
          type: 'image/webp',
          srcSet: image ? getImageLink(image) : '/banner1.png',
        },
      ],
    },
  };

  return (
    <div className={styles.root}>
      {
        !!image && (
          <GatsbyImage
            image={heroImage}
            alt={title}
            className={styles.hero__image_bg}
          />
        )
      }
      <div className={styles.content} style={{ maxWidth: maxWidth }}>
        {header && <span className={styles.header}>{header}</span>}
        {title && <h2 className={styles.title}>{title}</h2>}
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        {ctaText && (
          <Button
            className={`${styles.ctaButton} ${ctaStyle}`}
            level={'primary'}
            onClick={ctaAction}
          >
            {ctaText}
          </Button>
        )}
        {ctaLink && (
          <Link className={styles.ctaLink} to={ctaTo}>
            {ctaLink}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Hero;
